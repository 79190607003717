<template>
    <div >
        <b-navbar type="dark" variant="dark" sticky>
            <b-navbar-brand style="padding-left-12rem" href="#" @click.prevent="goToRoute({name:'home'})">JV-Living</b-navbar-brand>
            <b-navbar-nav>
                <b-nav-item :href="'/'" @click.prevent="goToRoute({name:'home'})">Home</b-nav-item>
                
                <!-- Navbar dropdowns -->
                <b-nav-item-dropdown text="Projekt" right>
                    <b-dropdown-item @click.prevent="goToRoute({name:'description'})">Beschreibung</b-dropdown-item>
                    <b-dropdown-item @click.prevent="goToRoute({name:'location'})">Lage</b-dropdown-item>
                    <b-dropdown-item @click.prevent="goToRoute({name:'blueprintFlat'})">Grundrisse (Wohnungen)</b-dropdown-item>
                    <b-dropdown-item @click.prevent="goToRoute({name:'musterFlat'})">Musterwohnung</b-dropdown-item>
                    <b-dropdown-item @click.prevent="goToRoute({name:'blueprintFloor'})">Grundrisse (Etage)</b-dropdown-item>
                    <b-dropdown-item @click.prevent="goToRoute({name:'view'})">Aussenansicht</b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item @click.prevent="goToRoute({name:'contact'})">Kontakt</b-nav-item>
            </b-navbar-nav>
        </b-navbar>
    </div>
</template>

<script>
export default {
  name: "TopNav",
  data() {
    return {
    };
  },
  components: {
  },
  methods: {
    goToRoute(params){
        this.$router.push(params)
    },
  },
  computed: {
  },
  created() {
    
  },
  mounted() {
    
  },

};
</script>

<style>
.navbar-expand {
    flex-flow: row nowrap;
    justify-content: space-around;
}
.nav-link{
    font-size: 1rem;
}
</style>